import React, { useId } from 'react';
import { Icon, Link, Container, Header } from '@amzn/awsui-components-react';

interface ExternalLinkItemProps {
    href: string;
    text: string;
}

interface ExternalLinkGroupProps {
    variant?: 'default' | 'container';
    header?: string;
    groupAriaLabel?: string;
    items: Array<ExternalLinkItemProps>;
}

function ExternalLinkItem({ href, text }: ExternalLinkItemProps) {
    return (
        <Link href={href} target='_blank'>
            {text}
        </Link>
    );
}

interface SeparatedListProps {
    ariaLabel?: string;
    ariaLabelledBy?: string;
    items: Array<React.ReactNode>;
}

export function SeparatedList({ ariaLabel, ariaLabelledBy, items }: SeparatedListProps) {
    return (
        <ul aria-label={ariaLabel} aria-labelledby={ariaLabelledBy}>
            {items.map((item, index) => (
                <li key={index}>{item}</li>
            ))}
        </ul>
    );
}

export default function ExternalLinkGroup({
    header = 'Learn more',
    groupAriaLabel,
    items,
    variant = 'default'
}: ExternalLinkGroupProps) {
    const externalIcon = (
        <span role='img' aria-label='Links open in a new tab'>
            <Icon name='external' size='inherit' />
        </span>
    );

    const headerId = `header-${useId()}`;

    if (variant === 'container') {
        return (
            <Container
                header={
                    <Header>
                        <span id={headerId}>
                            {header} {externalIcon}
                        </span>
                    </Header>
                }
            >
                <SeparatedList
                    ariaLabel={groupAriaLabel}
                    ariaLabelledBy={groupAriaLabel ? undefined : headerId}
                    items={items.map((item, index) => (
                        <ExternalLinkItem key={index} href={item.href} text={item.text} />
                    ))}
                />
            </Container>
        );
    }

    return (
        <>
            <h3 id={headerId}>
                {header} {externalIcon}
            </h3>
            <ul aria-label={groupAriaLabel} aria-labelledby={groupAriaLabel ? undefined : headerId}>
                {items.map((item, index) => (
                    <li key={index}>
                        <ExternalLinkItem href={item.href} text={item.text} />
                    </li>
                ))}
            </ul>
        </>
    );
}
