import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PlmInstance } from '../open-api/generated-src';

export const defaultPLMId = 'AgilePLM';

export type PlmIDType = string | undefined | null;
export type PlmInstancesType = PlmInstance[];
export enum RetrievePlmStatusType {
    Pending = 'pending',
    Loading = 'loading',
    Finished = 'finished',
    Error = 'error'
}
interface PlmState {
    plmId: PlmIDType;
    retrievePlmStatus: RetrievePlmStatusType;
    plmInstances: PlmInstancesType;
}

const initialState: PlmState = {
    plmId: null,
    retrievePlmStatus: RetrievePlmStatusType.Loading,
    plmInstances: []
};

const plmSlice = createSlice({
    name: 'plm',
    initialState,
    reducers: {
        setPlmId: (state, action: PayloadAction<PlmIDType>) => {
            state.plmId = action.payload;
        },

        setRetrievePlmStatus: (state, action: PayloadAction<RetrievePlmStatusType>) => {
            state.retrievePlmStatus = action.payload;
        },
        setPlmInstances: (state, action: PayloadAction<PlmInstancesType>) => {
            state.plmInstances = action.payload;
        },
        updatePlmInstances: (
            state,
            action: PayloadAction<(prev: PlmInstancesType) => PlmInstancesType>
        ) => {
            state.plmInstances = action.payload(state.plmInstances);
        }
    }
});
export const { setPlmId, setPlmInstances, updatePlmInstances, setRetrievePlmStatus } =
    plmSlice.actions;

export default plmSlice.reducer;
