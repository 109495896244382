import * as React from 'react';
import {
    Badge,
    Table,
    Box,
    SpaceBetween,
    Button,
    Header,
    Pagination
} from '@amzn/awsui-components-react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { FieldsMappingType, ParsedData } from '../types';

interface ImportedCSVDataTableProps {
    fieldsMapping: FieldsMappingType;
    parsedData: ParsedData[];
    setParsedData: React.Dispatch<React.SetStateAction<ParsedData[]>>;
    onBack: () => void;
}
export default function ImportedCSVDataTable({
    fieldsMapping,
    parsedData,
    setParsedData,
    onBack
}: ImportedCSVDataTableProps) {
    const [selectedItems, setSelectedItems] = React.useState<ParsedData[]>([]);
    const getColumnDefinitions = function (fieldsMapping) {
        return Object.keys(fieldsMapping).map((key: string) => {
            return {
                id: key,
                header: (
                    <>
                        {key} <Badge>{fieldsMapping[key]}</Badge>
                    </>
                ),
                minWidth: 176,
                cell: (data) => (fieldsMapping[key] ? data[fieldsMapping[key]] : null),
                sortingField: key
            };
        });
    };
    const columnDefinitions = getColumnDefinitions(fieldsMapping);

    const { items, allPageItems, paginationProps } = useCollection(parsedData, {
        pagination: { pageSize: 10 },
        sorting: {},
        filtering: {}
    });

    const onDeleteClick = () => {
        setParsedData((prevData) => {
            const idsToRemove = new Set(selectedItems.map((item) => item.id));
            return prevData.filter((item) => !idsToRemove.has(item.id));
        });
        setSelectedItems([]);
    };
    return (
        <Table
            stickyHeader={true}
            header={
                <Header
                    variant='h1'
                    description={`Review the data below to ensure it's correct before importing. Total items: ${allPageItems.length}`}
                    actions={
                        <SpaceBetween direction='horizontal' size='xs'>
                            <Button
                                data-testid='delete'
                                disabled={selectedItems.length === 0}
                                onClick={onDeleteClick}
                            >
                                Delete
                            </Button>
                        </SpaceBetween>
                    }
                >
                    Review and Import Data
                </Header>
            }
            selectedItems={selectedItems}
            onSelectionChange={(event) => setSelectedItems(event.detail.selectedItems)}
            selectionType='multi'
            renderAriaLive={({ firstIndex, lastIndex, totalItemsCount }) =>
                `Displaying items ${firstIndex} to ${lastIndex} of ${totalItemsCount}`
            }
            ariaLabels={{
                selectionGroupLabel: 'Items selection',
                allItemsSelectionLabel: () => 'select all'
            }}
            columnDefinitions={columnDefinitions}
            items={items}
            loadingText='Loading resources'
            trackBy='id'
            empty={
                <Box margin={{ vertical: 'xs' }} textAlign='center' color='inherit'>
                    <SpaceBetween size='m'>
                        <b>No data to import</b>
                        <Button onClick={onBack}>Go back and upload CSV</Button>
                    </SpaceBetween>
                </Box>
            }
            pagination={<Pagination {...paginationProps} />}
        />
    );
}
