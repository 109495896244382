import React, { useState, useMemo } from 'react';
import {
    Container,
    Header,
    Input,
    FormField,
    SpaceBetween,
    Button,
    Form,
    NonCancelableCustomEvent,
    InputProps,
    Flashbar,
    FlashbarProps,
    ColumnLayout
} from '@amzn/awsui-components-react';
import { useNavigate } from 'react-router-dom';
import CustomAppLayout from '../common/CustomAppLayout';
import Breadcrumbs from '../common/Breadcrumbs';
import { subscribePart, operationConfigs, OperationType } from './partSubscriptionUtils';
import { SubscribePartInput } from '../../open-api/generated-src';
import {
    validateEmpty,
    ValidationConfigType,
    validateField
} from '../../utils/form-validation-config';
import useConfirmationModal from '../hooks/useConfirmationModal';
import useFlashMessage, { AddFlashMessageType } from '../hooks/useFlashMessage';
import useBeforeUnload from '../hooks/useBeforeUnload';

const defaultFormData = {
    manufacturer: '',
    manufacturerPartNumber: ''
};

type ErrorsType = {
    manufacturer: string;
    manufacturerPartNumber: string;
};

const defaultErrors = {
    manufacturer: '',
    manufacturerPartNumber: ''
};

const fieldsToValidate = ['manufacturer', 'manufacturerPartNumber'];
const validationConfig: ValidationConfigType = {
    manufacturer: [{ validate: validateEmpty, errorText: 'Manufacturer is required.' }],
    manufacturerPartNumber: [
        { validate: validateEmpty, errorText: 'Manufacturer Part Number is required.' }
    ]
};

export default function AddSinglePartPage({
    addFlashMessage,
    retrievePartsCallback
}: {
    addFlashMessage: AddFlashMessageType;
    retrievePartsCallback: () => Promise<void>;
}) {
    const [localFlashMessages, setLocalFlashMessages] = useState<FlashbarProps.MessageDefinition[]>(
        []
    );
    const [iAddLoading, setIsAddLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const [formData, setFormData] = useState<SubscribePartInput>(defaultFormData);
    const { addFlashMessage: addLocalFlashMessage } = useFlashMessage({
        setFlashMessages: setLocalFlashMessages
    });
    const [errors, setErrors] = useState<ErrorsType>(defaultErrors);

    const isDirty = useMemo(() => {
        return JSON.stringify(formData) !== JSON.stringify(defaultFormData);
    }, [formData]);

    const { ConfirmationModal, guardNavigation } = useConfirmationModal({
        isDirty,
        clearForm: () => {
            setFormData(defaultFormData);
            setErrors(defaultErrors);
        }
    });

    useBeforeUnload(isDirty);

    const onCancelClick = (e) => {
        e.preventDefault();
        guardNavigation(() => navigate(-1));
    };

    const onSubmitHandler = async (e: React.FormEvent) => {
        e.preventDefault();
        const newErrors = { ...errors };
        fieldsToValidate.forEach((attribute) => {
            const { errorText } = validateField(
                attribute,
                validationConfig,
                formData[attribute],
                formData[attribute]
            );
            newErrors[attribute] = errorText;
        });
        setErrors((prevErrors) => ({ ...prevErrors, ...newErrors }));
        const hasErrors = Object.values(newErrors).some((item) => item !== null);
        if (!hasErrors) {
            setIsAddLoading(true);
            try {
                await subscribePart(formData);
                addFlashMessage({
                    type: 'success',
                    dismissible: true,
                    content: operationConfigs[OperationType.Subscribe].successMessageSingle,
                    action: <Button onClick={retrievePartsCallback}>Refresh</Button>
                });
                navigate(-1);
            } catch (err) {
                addLocalFlashMessage({
                    type: 'error',
                    dismissible: true,
                    content: operationConfigs[OperationType.Subscribe].errorMessageSingle(err)
                });
            } finally {
                setIsAddLoading(false);
            }
        }
    };

    const handleInputChange = (
        field: keyof typeof formData,
        event: NonCancelableCustomEvent<InputProps.ChangeDetail>
    ) => {
        setFormData((prevData) => ({
            ...prevData,
            [field]: event.detail.value
        }));
    };

    return (
        <CustomAppLayout
            notifications={<Flashbar items={localFlashMessages} stackItems />}
            onFollowEnhance={guardNavigation}
            breadcrumbs={
                <Breadcrumbs
                    onFollowEnhance={guardNavigation}
                    items={[
                        {
                            text: 'Home',
                            href: '/parts'
                        },
                        {
                            text: 'Add a Part',
                            href: '/parts/add-a-part'
                        }
                    ]}
                />
            }
            contentType='form'
            content={
                <>
                    <form onSubmit={onSubmitHandler}>
                        <Form
                            actions={
                                <SpaceBetween direction='horizontal' size='xs'>
                                    <Button variant='link' onClick={onCancelClick}>
                                        Cancel
                                    </Button>
                                    <Button variant='primary' loading={iAddLoading}>
                                        Add
                                    </Button>
                                </SpaceBetween>
                            }
                            errorIconAriaLabel='Error'
                        >
                            <Container header={<Header variant='h1'>Add a Part</Header>}>
                                <ColumnLayout columns={2}>
                                    <SpaceBetween size='l'>
                                        <FormField
                                            label='Manufacturer'
                                            errorText={errors.manufacturer}
                                        >
                                            <Input
                                                value={formData.manufacturer}
                                                onChange={(e) =>
                                                    handleInputChange('manufacturer', e)
                                                }
                                            />
                                        </FormField>
                                        <FormField
                                            label='Manufacturer Part Number'
                                            errorText={errors.manufacturerPartNumber}
                                        >
                                            <Input
                                                value={formData.manufacturerPartNumber}
                                                onChange={(e) =>
                                                    handleInputChange('manufacturerPartNumber', e)
                                                }
                                            />
                                        </FormField>
                                    </SpaceBetween>
                                </ColumnLayout>
                            </Container>
                        </Form>
                    </form>
                    <ConfirmationModal />
                </>
            }
        />
    );
}
