import React from 'react';
import {
    StatusIndicator,
    TableProps,
    CollectionPreferencesProps,
    PropertyFilterProps
} from '@amzn/awsui-components-react';
import { StatusIndicatorType } from '../components/types';
import { RetrievedPartData } from '../open-api/generated-src/api';
import { getStringFromReactNode } from '../utils/commons';

export function usePartsColumnDefinitions(): TableProps.ColumnDefinition<RetrievedPartData>[] {
    return [
        {
            id: 'id',
            cell: (item) => item.id,
            header: 'ID',
            minWidth: '50px',
            sortingField: 'id'
        },
        {
            id: 'dataProviderPartId',
            cell: (item) => item.dataProviderPartId,
            header: 'Data Provider Part ID',
            minWidth: '100px',
            sortingField: 'dataProviderPartId'
        },
        {
            id: 'internalPartNumber',
            header: 'Internal Part Number',
            cell: (item) => item.internalPartNumber,
            minWidth: '100px',
            sortingField: 'internalPartNumber'
        },
        {
            id: 'manufacturerPartNumber',
            cell: (item) => item.manufacturerPartNumber,
            header: 'Manufacturer Part Number',
            minWidth: '160px',
            sortingField: 'manufacturerPartNumber'
        },
        {
            id: 'manufacturer',
            header: 'Manufacturer',
            cell: (item) => item.manufacturer,
            minWidth: '50px',
            maxWidth: '200px',
            sortingField: 'manufacturer'
        },
        {
            id: 'partType',
            header: 'Part Type',
            cell: (item) => item.partType,
            minWidth: '100px',
            maxWidth: '200px',
            sortingField: 'partType'
        },
        {
            id: 'subscriptionStatus',
            header: 'Subscription Status',
            cell: (item) => (
                <StatusIndicator
                    type={
                        item.subscriptionStatus === 'REMOVED'
                            ? StatusIndicatorType.ERROR
                            : StatusIndicatorType.SUCCESS
                    }
                >
                    {item.subscriptionStatus}
                </StatusIndicator>
            ),
            minWidth: '150px',
            sortingField: 'subscriptionStatus'
        },
        {
            id: 'subscriptionUpdateDate',
            header: 'Subscription Update Date',
            cell: (item) => item.subscriptionUpdateDate,
            minWidth: '150px',
            sortingField: 'subscriptionUpdateDate'
        },
        {
            id: 'plmPartId',
            header: 'PLM Part ID',
            cell: (item) => item.plmPartId,
            minWidth: '150px',
            sortingField: 'plmPartId'
        }
    ];
}

export function getFilteringProperty(
    columnDefinitions: TableProps.ColumnDefinition<RetrievedPartData>[]
): PropertyFilterProps.FilteringProperty[] {
    return columnDefinitions.map((column) => {
        const newColumn: PropertyFilterProps.FilteringProperty = {
            key: '',
            groupValuesLabel: '',
            propertyLabel: ''
        };
        newColumn.propertyLabel = getStringFromReactNode(column.header);
        newColumn.key = column.id || '';
        newColumn.groupValuesLabel = `${column.header} values`;
        newColumn.operators = ['='];
        return newColumn;
    });
}

export const stringOperators: string[] = [':', '!:', '=', '!=', '^', '!^'];

export const CONTENT_SELECTOR_OPTIONS: CollectionPreferencesProps.ContentDisplayOption[] = [
    { id: 'id', label: 'ID', alwaysVisible: true },
    { id: 'dataProviderPartId', label: 'Data Provider Part ID' },
    {
        id: 'internalPartNumber',
        label: 'Internal Part Number'
    },
    {
        id: 'manufacturerPartNumber',
        label: 'Manufacturer Part Number'
    },
    {
        id: 'manufacturer',
        label: 'Manufacturer'
    },
    {
        id: 'partType',
        label: 'Part Type'
    },
    { id: 'subscriptionStatus', label: 'Subscription Status' },
    {
        id: 'subscriptionUpdateDate',
        label: 'Subscription Update Date'
    },
    {
        id: 'plmPartId',
        label: 'PLM Part ID'
    }
];

export const DEFAULT_PAGESIZE = 100;

export const DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences = {
    pageSize: DEFAULT_PAGESIZE,
    wrapLines: true
};
