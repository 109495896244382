// Promise polyfill
import 'core-js/features/promise';
import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import App from './components/App';
import '@amzn/awsui-global-styles/polaris.css';
import { Provider } from 'react-redux';
import { I18nProvider } from '@amzn/awsui-components-react/polaris/i18n';
import enMessages from '@amzn/awsui-components-react/polaris/i18n/messages/all.en.json';
import { store } from './state/store';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import sigv4Interceptor from './auth/AxiosSigv4Interceptor';
import { setupAuth, signIn } from './auth/AmplifyAuthToken';
import ApiFactory from './open-api/ApiFactory';
import { DefaultApi } from './open-api/generated-src/api';
import { initializeAppSettings } from './config/AppSettings';
import mockRetrievePartsResponse from '../localSettings/parts.json';
import mockMissingSubstances from '../localSettings/missingSubstances.json';
import mockInternalAttributes from '../localSettings/internalAttributes.json';
import mockPlmAttributes from '../localSettings/plmAttributes.json';
import mockDataProviderAttributes from '../localSettings/dataProviderAttributes.json';
import mockMappingPlmAttributes from '../localSettings/mappingPlmAttributes.json';
import mockMappingProviderAttributes from '../localSettings/mappingProviderAttributes.json';
import mockPlmInstances from '../localSettings/plmInstances.json';

async function initializeApp() {
    await initializeAppSettings();
    setupAuth().then((authenticated) => {
        if (authenticated) {
            renderApp();
        } else {
            signIn();
        }
    });
    axios.interceptors.request.use(sigv4Interceptor);
    apiInstance.setApi(ApiFactory());
}

function renderApp() {
    const root = ReactDOM.createRoot(document.getElementById('app') as HTMLElement);
    mockApiCall();
    root.render(
        <StrictMode>
            <Provider store={store}>
                <I18nProvider locale='en' messages={[enMessages]}>
                    <App />
                </I18nProvider>
            </Provider>
        </StrictMode>
    );
}

function createApiInstance() {
    let api: DefaultApi | null = null;

    const getApi = () => api;

    const setApi = (newApi: DefaultApi | null) => {
        api = newApi;
    };

    return {
        getApi,
        setApi
    };
}

function getSuccessMockResponse(config: AxiosRequestConfig) {
    const { url, method } = config;

    if (!url || !method) return { message: 'Invalid request configuration' };

    // Helper function to check URL and method
    const matchRoute = (path: string, reqMethod: string) =>
        url.includes(path) && method.toLowerCase() === reqMethod.toLowerCase();

    // Parts
    if (matchRoute('/parts/subscribe/single', 'post'))
        return { statusCode: 200, statusMessage: 'Added 1 part to the data provider subscription' };

    if (matchRoute('/parts/subscribe/list', 'post'))
        return {
            statusCode: 200,
            statusMessage: 'Added [x] part(s) to the data provider subscription'
        };

    if (matchRoute('/parts/unsubscribe/single', 'post'))
        return {
            statusCode: 200,
            statusMessage: 'Removed 1 part from the data provider subscription'
        };

    if (matchRoute('/parts/unsubscribe/list', 'post'))
        return {
            statusCode: 200,
            statusMessage: 'Removed [x] part(s) from the data provider subscription'
        };

    if (matchRoute('/parts', 'get') || matchRoute('/parts/search', 'get'))
        return mockRetrievePartsResponse;

    if (matchRoute('/parts/update', 'post'))
        return { statusCode: 200, statusMessage: 'Started PLM update job' };

    if (matchRoute('/parts/update/single', 'post'))
        return { statusCode: 200, statusMessage: 'Updated 1 part in PLM' };

    if (matchRoute('/parts/update/list', 'post'))
        return { statusCode: 200, statusMessage: 'Updated [x] part(s) in PLM' };

    // Missing substances
    if (matchRoute('/substances', 'get') || matchRoute('/substances/search', 'get'))
        return mockMissingSubstances;

    if (matchRoute('/substances/missing', 'post'))
        return { statusCode: 200, statusMessage: 'Successfully created substance in PLM' };

    if (matchRoute('/substances/missing/all', 'post'))
        return { statusCode: 200, statusMessage: 'Began missing substance creation operation' };

    if (matchRoute('/substances/alias', 'post'))
        return {
            statusCode: 200,
            statusMessage: "Created alias '[alias]' for substance '[substance]'"
        };

    // Attribute APIs
    if (matchRoute('/attributes/internal', 'get')) return mockInternalAttributes;
    if (matchRoute('/attributes/internal', 'post'))
        return { statusCode: 200, statusMessage: 'Successfully created attribute [attributeName]' };
    if (matchRoute('/attributes/internal', 'delete'))
        return { statusCode: 200, statusMessage: 'Successfully deleted attribute [attributeName]' };

    if (matchRoute('/attributes/provider', 'get')) return mockDataProviderAttributes;
    if (matchRoute('/attributes/provider', 'post'))
        return { statusCode: 200, statusMessage: 'Successfully created attribute [attributeName]' };
    if (matchRoute('/attributes/provider', 'delete'))
        return { statusCode: 200, statusMessage: 'Successfully deleted attribute [attributeName]' };

    if (matchRoute('/attributes/plm', 'get')) return mockPlmAttributes;
    if (matchRoute('/attributes/plm', 'post'))
        return { statusCode: 200, statusMessage: 'Successfully created attribute [attributeName]' };
    if (matchRoute('/attributes/plm', 'delete'))
        return { statusCode: 200, statusMessage: 'Successfully deleted attribute [attributeName]' };

    if (matchRoute('/attributes/mapping/provider', 'post'))
        return {
            statusCode: 200,
            statusMessage: 'Successfully mapped [internalAttribute] to [dataProviderAttribute]'
        };
    if (matchRoute('/attributes/mapping/provider', 'get')) return mockMappingProviderAttributes;

    if (matchRoute('/attributes/mapping/plm', 'post'))
        return {
            statusCode: 200,
            statusMessage: 'Successfully mapped [internalAttribute] to [plmAttribute]'
        };
    if (matchRoute('/attributes/mapping/plm', 'get')) return mockMappingPlmAttributes;

    // PLM APIs
    if (matchRoute('/plm/instance', 'get')) return mockPlmInstances;
    if (matchRoute('/plm/instance', 'post'))
        return { statusCode: 200, statusMessage: 'Created new PLM instance with ID [plmId]' };
    if (matchRoute('/plm/instance', 'put'))
        return { statusCode: 200, statusMessage: 'Updated PLM instance with ID [plmId]' };
    if (matchRoute('/plm/instance', 'delete'))
        return {
            statusCode: 200,
            statusMessage: 'Scheduled PLM instance [plmId] for deletion on [scheduledDeletionDate]'
        };
    if (matchRoute('/plm/instance/canceldeletion', 'put'))
        return { statusCode: 200, statusMessage: 'Unscheduled deletion for PLM instance [plmId]' };

    // Default response if no match is found
    return { statusCode: 404, statusMessage: 'No mock response found for this request' };
}

// TODO: remove after api work
function mockApiCall() {
    const IS_MOCK_API = true;

    axios.interceptors.request.use(
        function (config) {
            // Simulate a delay of 1 seconds
            return new Promise((resolve) => setTimeout(() => resolve(config), 1000));
        },
        function (error) {
            return Promise.reject(error);
        }
    );
    // Add response interceptor
    axios.interceptors.response.use(
        (response: AxiosResponse) => {
            if (IS_MOCK_API) {
                const successMockResponse = getSuccessMockResponse(response.config);
                return Promise.resolve({ data: successMockResponse });
            }
            return response;
        },
        (error) => {
            if (
                error.config.url.includes('/parts/subscribe/list') &&
                error.config.method === 'post'
            ) {
                return Promise.reject(error);
            }
            if (error.config.url.includes('/plm/instance') && error.config.method === 'delete') {
                return Promise.reject(error);
            }
            if (error.config.url.includes('/substances/alias') && error.config.method === 'post') {
                return Promise.reject(error);
            }
            if (
                error.config.url.includes('/attributes/mapping/plm') &&
                error.config.method === 'post'
            ) {
                return Promise.reject(error);
            }

            const successMockResponse = getSuccessMockResponse(error.config);

            return Promise.resolve({ data: successMockResponse });
        }
    );
}

export const apiInstance = createApiInstance();
initializeApp();
