import { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
    GetPlmInstancesResponseContent,
    CreatePlmInstanceRequestContent,
    CreatePlmInstanceResponseContent,
    UpdatePlmInstanceRequestContent,
    UpdatePlmInstanceResponseContent,
    SchedulePlmInstanceDeletionResponseContent,
    CancelPlmInstanceDeletionRequestContent,
    CancelPlmInstanceDeletionResponseContent,
    PlmInstance
} from '../../open-api/generated-src/api';
import { apiInstance } from '../../index';
import {
    setPlmInstances,
    setPlmId,
    defaultPLMId,
    setRetrievePlmStatus,
    RetrievePlmStatusType
} from '../../state/plmSlice';

export interface PlmIdOption {
    id: string;
    text: string;
    iconName: 'check' | undefined;
}

export default function usePlmInstances() {
    const [plmIdOptions, setPlmIdOptions] = useState<PlmIdOption[]>([]);
    const dispatch = useDispatch();

    const getOptions = useCallback((plmInstances: PlmInstance[]): PlmIdOption[] => {
        return plmInstances
            .filter((plm) => plm.enabled)
            .map((plm) => {
                return {
                    id: plm.plmId,
                    text: plm.name,
                    iconName: plm.plmId === defaultPLMId ? 'check' : undefined
                };
            });
    }, []);

    const retrievePlmInstances = useCallback(async () => {
        dispatch(setRetrievePlmStatus(RetrievePlmStatusType.Loading));
        try {
            const result = await getPlmInstances();
            setPlmIdOptions(getOptions(result.plmInstances));
            if (
                result.plmInstances.find((instance) => instance.plmId === defaultPLMId) !==
                undefined
            ) {
                dispatch(setPlmId(defaultPLMId));
            } else {
                dispatch(setPlmId(undefined));
            }
            dispatch(setPlmInstances(result.plmInstances));
            dispatch(setRetrievePlmStatus(RetrievePlmStatusType.Finished));
        } catch (error) {
            // Fallback to Default
            dispatch(setPlmId(defaultPLMId));
            dispatch(setRetrievePlmStatus(RetrievePlmStatusType.Error));
            console.error(error);
        }
    }, [dispatch, getOptions]);

    return {
        plmIdOptions,
        setPlmIdOptions,
        retrievePlmInstances
    };
}

export async function getPlmInstances(): Promise<GetPlmInstancesResponseContent> {
    try {
        const response = await apiInstance.getApi()!.getPlmInstances();
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export async function createPlmInstance({
    plmId,
    plmType,
    name,
    enabled
}: CreatePlmInstanceRequestContent): Promise<CreatePlmInstanceResponseContent> {
    try {
        const response = await apiInstance
            .getApi()!
            .createPlmInstance({ plmId, plmType, name, enabled });
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export async function updatePlmInstance({
    plmId,
    plmType,
    name,
    enabled
}: UpdatePlmInstanceRequestContent): Promise<UpdatePlmInstanceResponseContent> {
    try {
        const response = await apiInstance
            .getApi()!
            .updatePlmInstance({ plmId, plmType, name, enabled });
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export async function schedulePlmInstanceDeletion(
    plmId
): Promise<SchedulePlmInstanceDeletionResponseContent> {
    try {
        const response = await apiInstance.getApi()!.schedulePlmInstanceDeletion(plmId);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export async function cancelPlmInstanceDeletion({
    plmId
}: CancelPlmInstanceDeletionRequestContent): Promise<CancelPlmInstanceDeletionResponseContent> {
    try {
        const response = await apiInstance.getApi()!.cancelPlmInstanceDeletion({ plmId });
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}
