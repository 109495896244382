import React, { useState, useRef } from 'react';
import {
    Input,
    SpaceBetween,
    Button,
    Header,
    Select,
    Container,
    Table,
    TableProps,
    Flashbar,
    FlashbarProps,
    ExpandableSection,
    FormField,
    Form,
    Grid,
    Alert,
    Modal,
    Box
} from '@amzn/awsui-components-react';
import CustomAppLayout from './common/CustomAppLayout';
import Breadcrumbs from './common/Breadcrumbs';
import useAttributes, {
    NO_MAPPING_LABEL,
    MappingAttributesType,
    FormErrorsType,
    CreateAttributeFormType,
    DEFAULT_FORM_DATA,
    mappingTableEditErrorsStore,
    COLUMN_IDS
} from './hooks/useAttributes';
import useFlashMessage from './hooks/useFlashMessage';
import { TableEmptyState } from './common';

const DEFAULT_ERRORS: FormErrorsType = {
    attributeName: '',
    attributeType: ''
};

const ATTRIBUTE_CATEGORIES = [
    {
        label: 'Internal Attribute',
        value: COLUMN_IDS.INTERNAL_ATTRIBUTE
    },
    {
        label: 'PLM Attribute',
        value: COLUMN_IDS.PLM_ATTRIBUTE
    },
    {
        label: 'Data Provider Attribute',
        value: COLUMN_IDS.DATA_PROVIDER_ATTRIBUTE
    }
];

export default function MappingPage() {
    const [flashMessages, setFlashMessages] = useState<FlashbarProps.MessageDefinition[]>([]);
    const [formData, setFormData] = useState<CreateAttributeFormType>(DEFAULT_FORM_DATA);
    const [formErrors, setFormErrors] = useState<FormErrorsType>(DEFAULT_ERRORS);
    const [isEditFieldDirty, setIsEditFieldDirty] = useState<boolean>(false);
    const { addFlashMessage } = useFlashMessage({ setFlashMessages });
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const tableRef = useRef<TableProps.Ref>(null);

    const {
        isCreateLoading,
        isMappingsLoading,
        plmAttributesStatus,
        dataProviderAttributeStatus,
        attributeMappings,
        plmAttributesOptionsWithClear,
        dataProviderAttributesOptionsWithClear,
        handleTableInlineEditSubmit,
        handleFormSubmit,
        loadDataProviderAttribute,
        loadPlmAttributes,
        loadAttributesMapping
    } = useAttributes({
        addFlashMessage,
        formErrors,
        setFormErrors,
        formData,
        setFormData
    });

    const COLUMN_DEFINITIONS: TableProps.ColumnDefinition<MappingAttributesType>[] = [
        {
            id: COLUMN_IDS.INTERNAL_ATTRIBUTE,
            cell: (item) => item.internalAttribute,
            header: 'Internal Attribute',
            minWidth: '80px'
        },
        {
            id: COLUMN_IDS.PLM_ATTRIBUTE,
            cell: (item) => item.plmAttribute || NO_MAPPING_LABEL,
            header: 'PLM Attribute',
            minWidth: '160px',
            editConfig: {
                ariaLabel: 'PLM Attribute',
                editingCell: (item, { currentValue, setValue }) => {
                    const selectedOption =
                        plmAttributesOptionsWithClear.find(
                            (option) => option.value === (currentValue ?? item.plmAttribute)
                        ) || plmAttributesOptionsWithClear[0];
                    return (
                        <Select
                            selectedOption={selectedOption}
                            onChange={({ detail }) => {
                                setIsEditFieldDirty(true);
                                setValue(detail.selectedOption.value ?? item.plmAttribute);
                            }}
                            options={plmAttributesOptionsWithClear}
                            filteringType='auto'
                            autoFocus={true}
                            expandToViewport={true}
                            statusType={plmAttributesStatus}
                            loadingText='Loading plm attributes'
                            errorText='Failed to fetch plm attributes'
                        />
                    );
                },
                validation: (item) => {
                    const key = JSON.stringify(item) + COLUMN_IDS.PLM_ATTRIBUTE;
                    if (mappingTableEditErrorsStore.has(key)) {
                        return mappingTableEditErrorsStore.get(key);
                    }
                }
            }
        },
        {
            id: COLUMN_IDS.DATA_PROVIDER_ATTRIBUTE,
            cell: (item) => item.dataProviderAttribute || NO_MAPPING_LABEL,
            header: 'Data Provider Attribute',
            minWidth: '160px',
            editConfig: {
                ariaLabel: 'Data Provider Attribute',
                editingCell: (item, { currentValue, setValue }) => {
                    const selectedOption =
                        dataProviderAttributesOptionsWithClear.find(
                            (option) =>
                                option.value === (currentValue ?? item.dataProviderAttribute)
                        ) || dataProviderAttributesOptionsWithClear[0];
                    return (
                        <Select
                            selectedOption={selectedOption}
                            onChange={({ detail }) => {
                                setIsEditFieldDirty(true);
                                setValue(detail.selectedOption.value ?? item.dataProviderAttribute);
                            }}
                            options={dataProviderAttributesOptionsWithClear}
                            filteringType='auto'
                            autoFocus={true}
                            expandToViewport={true}
                            statusType={dataProviderAttributeStatus}
                            loadingText='Loading provider attributes'
                            errorText='Failed to fetch provider attributes'
                        />
                    );
                },
                validation: (item) => {
                    const key = JSON.stringify(item) + COLUMN_IDS.DATA_PROVIDER_ATTRIBUTE;
                    if (mappingTableEditErrorsStore.has(key)) {
                        return mappingTableEditErrorsStore.get(key);
                    }
                }
            }
        }
    ];

    const handleFormInputChange = (field: keyof typeof formData, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [field]: value
        }));
    };

    const selectedAttributeCategoryOption = ATTRIBUTE_CATEGORIES.filter(
        (item) => item.value === formData.attributeCategory
    );

    return (
        <CustomAppLayout
            notifications={<Flashbar items={flashMessages} stackItems />}
            breadcrumbs={
                <Breadcrumbs
                    items={[
                        { text: 'Home', href: '/parts' },
                        {
                            text: 'Mapping',
                            href: '/mapping'
                        }
                    ]}
                />
            }
            content={
                <Container
                    header={
                        <Header variant='h1' description='Manage attribute mappings'>
                            Attribute Mapping
                        </Header>
                    }
                >
                    <SpaceBetween size='l'>
                        <ExpandableSection headerText='Create New Attribute' variant='container'>
                            <form onSubmit={handleFormSubmit}>
                                <Form
                                    actions={
                                        <Button variant='primary' loading={isCreateLoading}>
                                            Create
                                        </Button>
                                    }
                                    errorIconAriaLabel='Error'
                                >
                                    <Grid
                                        gridDefinition={[
                                            { colspan: { default: 12, s: 4 } },
                                            { colspan: { default: 12, s: 4 } },
                                            { colspan: { default: 12, s: 4 } }
                                        ]}
                                    >
                                        <FormField label='Attribute Category'>
                                            <Select
                                                selectedOption={selectedAttributeCategoryOption[0]}
                                                onChange={({ detail }) =>
                                                    handleFormInputChange(
                                                        'attributeCategory',
                                                        detail.selectedOption.value
                                                    )
                                                }
                                                options={ATTRIBUTE_CATEGORIES}
                                            />
                                        </FormField>
                                        <FormField
                                            label='Attribute Name'
                                            errorText={formErrors.attributeName}
                                        >
                                            <Input
                                                value={formData.attributeName}
                                                onChange={(e) =>
                                                    handleFormInputChange(
                                                        'attributeName',
                                                        e.detail.value
                                                    )
                                                }
                                            />
                                        </FormField>
                                        <FormField
                                            label='Attribute Type'
                                            errorText={formErrors.attributeType}
                                        >
                                            <Input
                                                value={formData.attributeType}
                                                onChange={(e) =>
                                                    handleFormInputChange(
                                                        'attributeType',
                                                        e.detail.value
                                                    )
                                                }
                                            />
                                        </FormField>
                                    </Grid>
                                </Form>
                            </form>
                        </ExpandableSection>
                        <Table
                            ref={tableRef}
                            header={
                                <Header
                                    variant='h2'
                                    description='Mapping between internal attributes, PLM attributes, and data provider attributes'
                                    actions={
                                        <SpaceBetween size='xs'>
                                            <Button
                                                onClick={() => {
                                                    loadDataProviderAttribute();
                                                    loadPlmAttributes();
                                                    loadAttributesMapping();
                                                }}
                                                ariaLabel='Refresh'
                                                iconName='refresh'
                                                loading={isMappingsLoading}
                                                loadingText={'Refreshing Attribute Mappings'}
                                            />
                                        </SpaceBetween>
                                    }
                                >
                                    Attribute Mapping Table
                                </Header>
                            }
                            stickyHeader={true}
                            items={attributeMappings}
                            columnDefinitions={COLUMN_DEFINITIONS}
                            submitEdit={handleTableInlineEditSubmit}
                            onEditCancel={(evt) => {
                                if (isEditFieldDirty) {
                                    evt.preventDefault();
                                    setModalVisible(true);
                                }
                            }}
                            variant='container'
                            loading={isMappingsLoading}
                            loadingText='Loading attributes'
                            empty={<TableEmptyState resourceName='Attribute Mapping' />}
                        />
                    </SpaceBetween>
                    <Modal
                        visible={modalVisible}
                        header='Discard changes'
                        closeAriaLabel='Close modal'
                        onDismiss={() => {
                            setModalVisible(false);
                        }}
                        footer={
                            <Box float='right'>
                                <SpaceBetween direction='horizontal' size='xs'>
                                    <Button
                                        variant='link'
                                        onClick={() => {
                                            setModalVisible(false);
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        variant='primary'
                                        onClick={() => {
                                            setModalVisible(false);
                                            setIsEditFieldDirty(false);
                                            tableRef.current?.cancelEdit?.();
                                        }}
                                    >
                                        Discard
                                    </Button>
                                </SpaceBetween>
                            </Box>
                        }
                    >
                        <Alert type='warning' statusIconAriaLabel='Warning'>
                            Are you sure you want to discard any unsaved changes?
                        </Alert>
                    </Modal>
                </Container>
            }
        />
    );
}
