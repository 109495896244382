import React, { useState } from 'react';
import { Input, Select } from '@amzn/awsui-components-react';
import { FILTER_FIELD_TYPE } from '../hooks/useParts';
import { BaseKeyDetail } from '@amzn/awsui-components-react/polaris/internal/events';

export default function PartsTableFilterContainer({
    showFilteredResult,
    setShowFilteredResult,
    submitFilterInput,
    filterFieldOptions,
    filteringField,
    setFilteringField,
    setFilteringText,
    filteredCount
}: {
    showFilteredResult: boolean;
    setShowFilteredResult: (value: React.SetStateAction<boolean>) => void;
    submitFilterInput: (event: CustomEvent<BaseKeyDetail>, inputText: string) => Promise<void>;
    filterFieldOptions: FILTER_FIELD_TYPE[];
    filteringField: FILTER_FIELD_TYPE;
    setFilteringField: (value: FILTER_FIELD_TYPE) => void;
    setFilteringText: (value: string) => void;
    filteredCount: string;
}) {
    const [typedText, setTypedText] = useState<string>('');
    return (
        <div className='filter-container'>
            <div className='filter-input'>
                <Input
                    data-testid='input-filter'
                    type='search'
                    value={typedText}
                    onChange={(event) => {
                        setShowFilteredResult(false);
                        setTypedText(event.detail.value);
                        if (event.detail.value === '') {
                            setFilteringText('');
                        }
                    }}
                    onKeyDown={(e) => submitFilterInput(e, typedText)}
                    ariaLabel='Find Parts'
                    placeholder='Find Parts'
                    clearAriaLabel='clear'
                />
            </div>
            <div className='filter-select'>
                <Select
                    data-testid='filter-field'
                    inlineLabelText='Filter Field'
                    options={filterFieldOptions}
                    selectedAriaLabel='Selected'
                    selectedOption={filteringField}
                    onChange={(event) => {
                        const newFilteringField = event.detail.selectedOption as FILTER_FIELD_TYPE;
                        setFilteringField(newFilteringField);
                        setShowFilteredResult(false);
                    }}
                    expandToViewport={true}
                />
            </div>
            {showFilteredResult && <span className='filtering-results'>{filteredCount}</span>}
        </div>
    );
}
