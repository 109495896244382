import { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { TableProps } from '@amzn/awsui-components-react';
import { apiInstance } from '../../index';
import {
    GetSubstancesResponseContent,
    SearchSubstancesResponseContent,
    CreateMissingSubstanceRequestContent,
    CreateMissingSubstanceResponseContent,
    CreateAllMissingSubstancesRequestContent,
    CreateAllMissingSubstancesResponseContent,
    CreateSubstanceAliasRequestContent,
    CreateSubstanceAliasResponseContent,
    RetrievedSubstanceData
} from '../../open-api/generated-src';
import { AddFlashMessageType } from './useFlashMessage';
import { RootState } from '../../state/store';
import { getErrorMessage, validatePlmId } from '../../utils/commons';

export type FILTER_FIELD_TYPE = { value: string; label: string };

export const fieldTitle: Record<keyof RetrievedSubstanceData, string> = {
    substanceId: 'Substance ID',
    name: 'Substance Name',
    casNumber: 'Cas Number',
    dataProviderNumber: 'Data Provider Number'
};

export const filterFieldOptions: FILTER_FIELD_TYPE[] = Object.entries(fieldTitle).map(
    ([key, label]) => ({
        value: key,
        label: label
    })
);

export type extraProperties = {
    plmAlias: string | null;
    status: SubstanceStatus;
};

export enum SubstanceStatus {
    MISSING = 'missing',
    CREATED = 'created'
}

export type SubstanceTableType = RetrievedSubstanceData & extraProperties;

export type substanceStatusOptionsType = {
    value: SubstanceStatus;
    label: string;
};

export const substanceStatusOptions: substanceStatusOptionsType[] = [
    {
        value: SubstanceStatus.MISSING,
        label: 'Missing'
    },
    { value: SubstanceStatus.CREATED, label: 'Created' }
];

export const substanceTableEditErrorsStore = new Map();

export default function useSubstances({
    selectedSubstances,
    addFlashMessage
}: {
    selectedSubstances: SubstanceTableType[];
    addFlashMessage: AddFlashMessageType;
}) {
    const plmId = useSelector((state: RootState) => state.plm.plmId);
    const [loading, setLoading] = useState<boolean>(true);
    const [addSubstanceloading, setAddSubstanceloading] = useState<boolean>(false);
    const [addAllSubstancesloading, setAddAllSubstancesloading] = useState<boolean>(false);
    const [substances, setSubstances] = useState<SubstanceTableType[]>([]);
    const [filterText, setFilterText] = useState<string>('');
    const [filterField, setFilterField] = useState<FILTER_FIELD_TYPE>(filterFieldOptions[0]);
    const [filterSubstanceStatus, setFilterSubstanceStatus] = useState<FILTER_FIELD_TYPE>(
        substanceStatusOptions[0]
    );

    const addNewProperties = useCallback(
        (substances: RetrievedSubstanceData[]): SubstanceTableType[] => {
            return substances.map(
                (item) =>
                    ({
                        ...item,
                        plmAlias: null,
                        status: filterSubstanceStatus.value
                    }) as SubstanceTableType
            );
        },
        [filterSubstanceStatus.value]
    );

    const retrieveSubstancesCallback = useCallback(async () => {
        if (plmId === null) {
            return;
        }
        if (filterText !== '') {
            return;
        }
        setLoading(true);
        try {
            const result = await getSubstances(filterSubstanceStatus.value, plmId);
            setSubstances(addNewProperties(result.substances));
        } catch (error) {
            addFlashMessage({
                type: 'error',
                dismissible: true,
                content: `Failed to retrieve substances. ${getErrorMessage(error)}`
            });
        } finally {
            setLoading(false);
        }
    }, [filterText, plmId, filterSubstanceStatus.value, addNewProperties, addFlashMessage]);

    const searchSubstancesCallback = useCallback(async () => {
        if (plmId === null) {
            return;
        }
        if (filterText === '') {
            return;
        }
        setLoading(true);
        try {
            const result = await await searchSubstances(
                filterField.value,
                filterText,
                filterSubstanceStatus.value,
                plmId
            );
            setSubstances(addNewProperties(result.substances));
        } catch (error) {
            addFlashMessage({
                type: 'error',
                dismissible: true,
                content: `Failed to search parts. ${getErrorMessage(error)}`
            });
        } finally {
            setLoading(false);
        }
    }, [
        filterText,
        filterField.value,
        filterSubstanceStatus.value,
        plmId,
        addFlashMessage,
        addNewProperties
    ]);

    useEffect(() => {
        retrieveSubstancesCallback();
    }, [retrieveSubstancesCallback]);

    const formEditSubmitHandler: TableProps.SubmitEditFunction<SubstanceTableType> = useCallback(
        async (currentItem, _column, value) => {
            const key = JSON.stringify(currentItem) + _column.id;
            substanceTableEditErrorsStore.delete(key);
            const alias = value as string;
            const newItem = { ...currentItem, plmAlias: alias, status: SubstanceStatus.CREATED };
            await createSubstanceAlias({ substanceId: currentItem.substanceId, alias: alias })
                .then(() => {
                    setSubstances((prevSubstances) =>
                        prevSubstances.map((item) =>
                            item.substanceId === currentItem.substanceId ? newItem : item
                        )
                    );
                })
                .catch((error) => {
                    substanceTableEditErrorsStore.set(key, getErrorMessage(error));
                    throw new Error('Inline edit error in substances table');
                });
        },
        []
    );

    const createMissingSubstanceHandler = useCallback(async () => {
        if (!validatePlmId(plmId, addFlashMessage)) return;
        setAddSubstanceloading(true);
        const { substanceId, name: substanceName } = selectedSubstances[0];
        try {
            const response = await createMissingSubstance({
                plmId,
                substanceId: substanceId
            });

            setSubstances((prevSubstances) =>
                prevSubstances.map((item) =>
                    item.substanceId === substanceId
                        ? { ...item, status: SubstanceStatus.CREATED }
                        : item
                )
            );

            addFlashMessage({ type: 'success', content: response.statusMessage });
        } catch (error) {
            addFlashMessage({
                type: 'error',
                content: `Failed to create substance '${substanceName}' in PLM '${plmId}'. ${getErrorMessage(
                    error
                )}`
            });
        } finally {
            setAddSubstanceloading(false);
        }
    }, [plmId, selectedSubstances, addFlashMessage]);

    const createAllMissingSubstancesHandler = useCallback(async () => {
        if (!validatePlmId(plmId, addFlashMessage)) return;
        setAddAllSubstancesloading(true);
        try {
            const response = await createAllMissingSubstances({ plmId });

            setSubstances((prevSubstances) =>
                prevSubstances.map((item) => ({ ...item, status: SubstanceStatus.CREATED }))
            );

            addFlashMessage({ type: 'success', content: response.statusMessage });
        } catch (error) {
            addFlashMessage({
                type: 'error',
                content: `Failed to complete missing substance creation operation in PLM '${plmId}'. ${getErrorMessage(
                    error
                )}`
            });
        } finally {
            setAddAllSubstancesloading(false);
        }
    }, [plmId, addFlashMessage]);

    return {
        loading,
        addSubstanceloading,
        addAllSubstancesloading,
        substances,
        filterText,
        filterField,
        filterSubstanceStatus,
        setFilterField,
        setFilterText,
        setFilterSubstanceStatus,
        formEditSubmitHandler,
        createMissingSubstanceHandler,
        createAllMissingSubstancesHandler,
        retrieveSubstancesCallback,
        searchSubstancesCallback
    };
}

export async function getSubstances(
    status?: string,
    plmId?: string
): Promise<GetSubstancesResponseContent> {
    try {
        const response = await apiInstance.getApi()!.getSubstances(status, plmId);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export async function searchSubstances(
    field: string,
    query: string,
    status?: string,
    plmId?: string
): Promise<SearchSubstancesResponseContent> {
    try {
        const response = await apiInstance.getApi()!.searchSubstances(field, query, status, plmId);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export async function createMissingSubstance({
    plmId,
    substanceId
}: CreateMissingSubstanceRequestContent): Promise<CreateMissingSubstanceResponseContent> {
    try {
        const response = await apiInstance.getApi()!.createMissingSubstance({ plmId, substanceId });
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export async function createAllMissingSubstances({
    plmId
}: CreateAllMissingSubstancesRequestContent): Promise<CreateAllMissingSubstancesResponseContent> {
    try {
        const response = await apiInstance.getApi()!.createAllMissingSubstances({ plmId });
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export async function createSubstanceAlias({
    substanceId,
    alias
}: CreateSubstanceAliasRequestContent): Promise<CreateSubstanceAliasResponseContent> {
    try {
        const response = await apiInstance.getApi()!.createSubstanceAlias({ substanceId, alias });
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}
