import { useCallback } from 'react';
import { FlashbarProps } from '@amzn/awsui-components-react';
import { nanoid } from '@reduxjs/toolkit';

export type AddFlashMessageType = (props: FlashbarProps.MessageDefinition) => void;

export default function useFlashMessage({ setFlashMessages }) {
    const addFlashMessage: AddFlashMessageType = useCallback(
        ({ type, id, content, dismissible, action }) => {
            const newId = id || nanoid();
            setFlashMessages((prev) => [
                {
                    type,
                    dismissible: dismissible === undefined ? true : dismissible,
                    dismissLabel: 'Dismiss message',
                    onDismiss: () =>
                        setFlashMessages((items) => items.filter((item) => item.id !== newId)),
                    id: newId,
                    content,
                    action
                },
                ...prev
            ]);
        },
        [setFlashMessages]
    );
    return { addFlashMessage };
}
