import React, { useState, useCallback } from 'react';
import {
    Modal,
    Box,
    SpaceBetween,
    Button,
    Alert,
    FormField,
    ColumnLayout,
    Input
} from '@amzn/awsui-components-react';

const DEFAULT_TITLE = 'Leave page';
const DEFAULT_CANCEL_LABEL = 'Cancel';
const DEFAULT_CONFIRM_LABEL = 'Leave';
const DEFAULT_CONTENT = `Are you sure that you want to leave the current page? The changes that you made won't be saved.`;

type ErrorMessage = {
    title: string;
    content: string;
};
export type ConfirmResult = {
    isSuccess: boolean;
    errorMessage?: ErrorMessage;
};

export interface ConfirmationModalProps {
    title?: string;
    content?: React.ReactNode;
    confirmLabel?: string;
    cancelLabel?: string;
    confirmConsentText?: string;
    isConfirmButtonDisabled?: boolean;
    onConfirm?: () => void | Promise<ConfirmResult>;
}

function ConfirmationModal({
    modalProps,
    closeModal,
    modalVisible
}: {
    modalProps: ConfirmationModalProps;
    closeModal: () => void;
    modalVisible: boolean;
}) {
    const [confirmationInputText, setConfirmationInputText] = useState('');
    const [errorMessage, setErrorMessage] = useState<ErrorMessage | undefined>();
    const [isConfirmButtonLoading, setIsConfirmButtonLoading] = useState<boolean>(false);

    const handleConfirm = async () => {
        if (!modalProps.onConfirm) return;
        setErrorMessage(undefined);
        setIsConfirmButtonLoading(true);
        const result = await modalProps.onConfirm();
        if (typeof result === 'object' && 'isSuccess' in result) {
            setErrorMessage(result.errorMessage);
            if (result.isSuccess) closeModal();
        } else {
            closeModal();
        }
        setIsConfirmButtonLoading(false);
    };

    const isConfirmButtonDisabled = () => {
        if (modalProps.isConfirmButtonDisabled !== undefined) {
            return modalProps.isConfirmButtonDisabled;
        }
        if (modalProps.confirmConsentText) {
            return (
                confirmationInputText.toLowerCase() !== modalProps.confirmConsentText.toLowerCase()
            );
        }
        return false;
    };
    return (
        <Modal
            visible={modalVisible}
            header={modalProps.title || DEFAULT_TITLE}
            closeAriaLabel='Close modal'
            onDismiss={closeModal}
            footer={
                <Box float='right'>
                    <SpaceBetween direction='horizontal' size='xs'>
                        <Button variant='link' onClick={closeModal}>
                            {modalProps.cancelLabel || DEFAULT_CANCEL_LABEL}
                        </Button>
                        <Button
                            variant='primary'
                            onClick={handleConfirm}
                            disabled={isConfirmButtonDisabled()}
                            loading={isConfirmButtonLoading}
                        >
                            {modalProps.confirmLabel || DEFAULT_CONFIRM_LABEL}
                        </Button>
                    </SpaceBetween>
                </Box>
            }
        >
            <SpaceBetween size='m'>
                {modalProps.content ? (
                    modalProps.content
                ) : (
                    <Alert type='warning' statusIconAriaLabel='Warning'>
                        {DEFAULT_CONTENT}
                    </Alert>
                )}
                {modalProps.confirmConsentText && (
                    <SpaceBetween size='m'>
                        <Box>
                            To avoid accidental deletions, we ask you to provide additional written
                            consent.
                        </Box>
                        <FormField
                            label={`To confirm this deletion, type "${modalProps.confirmConsentText}".`}
                        >
                            <ColumnLayout columns={2}>
                                <Input
                                    placeholder={modalProps.confirmConsentText}
                                    onChange={(event) =>
                                        setConfirmationInputText(event.detail.value)
                                    }
                                    value={confirmationInputText}
                                    ariaRequired={true}
                                />
                            </ColumnLayout>
                        </FormField>
                    </SpaceBetween>
                )}
                {errorMessage && (
                    <Alert statusIconAriaLabel='Error' type='error' header={errorMessage.title}>
                        {errorMessage.content}
                    </Alert>
                )}
            </SpaceBetween>
        </Modal>
    );
}

type GuardNavigationProps = {
    isDirty: boolean;
    clearForm: () => void;
};

/**
 * Custom hook for managing a confirmation modal.
 *
 * @param guardNavigationProps Optional props for guarding navigation with unsaved changes
 * @returns Object containing ConfirmationModal component, openModal function, and guardNavigation function
 */
export default function useConfirmationModal(guardNavigationProps?: GuardNavigationProps) {
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [modalProps, setModalProps] = useState<ConfirmationModalProps>({});

    /**
     * Opens the modal with the given props
     * @param props ConfirmationModalProps to set for the modal
     */
    const openModal = useCallback((props: ConfirmationModalProps) => {
        setModalProps(props);
        setModalVisible(true);
    }, []);

    const closeModal = useCallback(() => {
        setModalVisible(false);
    }, []);

    const ConfirmationModalCallback = useCallback(
        () => (
            <ConfirmationModal
                modalProps={modalProps}
                closeModal={closeModal}
                modalVisible={modalVisible}
            />
        ),
        [modalVisible, modalProps, closeModal]
    );

    /**
     * Function to guard navigation when there are unsaved changes
     * If there are unsaved changes, it opens a confirmation modal
     * If not, it directly calls the navigation handler
     */
    const guardNavigation = useCallback(
        (navigateHandler) => {
            if (guardNavigationProps) {
                if (guardNavigationProps.isDirty) {
                    openModal({
                        onConfirm: () => {
                            guardNavigationProps.clearForm();
                            navigateHandler();
                        }
                    });
                } else {
                    navigateHandler();
                }
            }
        },
        [openModal, guardNavigationProps]
    );

    return { ConfirmationModal: ConfirmationModalCallback, openModal, guardNavigation };
}
