import React, { useState, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
    SpaceBetween,
    Button,
    Table,
    Box,
    Pagination,
    StatusIndicator,
    TextFilter,
    CollectionPreferences,
    Alert,
    Header,
    TableProps,
    CollectionPreferencesProps
} from '@amzn/awsui-components-react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { useSelector } from 'react-redux';
import { RootState } from '../../state/store';
import { PlmInstance } from '../../open-api/generated-src';
import { StatusIndicatorType } from '../types';
import { TableEmptyState, TableNoMatchState } from '../common';
import { getErrorMessage, getTextFilterCounterText } from '../../utils/commons';
import { cancelPlmInstanceDeletion, schedulePlmInstanceDeletion } from '../hooks/usePlmInstances';
import { AddFlashMessageType } from '../hooks/useFlashMessage';
import { ConfirmResult } from '../hooks/useConfirmationModal';
import usePlmInstances from '../hooks/usePlmInstances';
import useConfirmationModal from '../hooks/useConfirmationModal';

const NO_SELECTION = 'Please select a PLM instance';
const INSTANCE_ENABLED =
    'Cannot schedule deletion for an enabled PLM instance. Please disable it first.';
const ALREADY_SCHEDULED =
    'Cannot schedule deletion for a PLM instance that is already scheduled for deletion.';
const NO_SCHEDULED_DELETION = 'No scheduled deletion exists for this PLM instance.';

const DEFAULT_PAGESIZE = 10;

export const DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences = {
    pageSize: DEFAULT_PAGESIZE,
    wrapLines: false
};

const COLUMN_DEFINITIONS: TableProps.ColumnDefinition<PlmInstance>[] = [
    {
        id: 'plmId',
        cell: (item) => item.plmId,
        header: 'PLM ID',
        minWidth: '80px'
    },
    {
        id: 'name',
        cell: (item) => item.name,
        header: 'PLM Name',
        minWidth: '160px'
    },
    {
        id: 'plmType',
        cell: (item) => item.plmType,
        header: 'PLM Type',
        minWidth: '100px'
    },
    {
        id: 'enabled',
        cell: (item) => (
            <StatusIndicator
                type={item.enabled ? StatusIndicatorType.SUCCESS : StatusIndicatorType.ERROR}
            >
                {item.enabled}
            </StatusIndicator>
        ),
        header: 'Enabled',
        minWidth: '100px'
    },
    {
        id: 'scheduledDeletionDate',
        cell: (item) => item.scheduledDeletionDate,
        header: 'Scheduled Deletion Date',
        minWidth: '200px'
    }
];

export default function PlmTable({
    addFlashMessage,
    setSelectedPlmInstances,
    selectedPlmInstances
}: {
    addFlashMessage: AddFlashMessageType;
    selectedPlmInstances: PlmInstance[];
    setSelectedPlmInstances: React.Dispatch<React.SetStateAction<PlmInstance[]>>;
}) {
    const { plmInstances, retrievePlmStatus } = useSelector((root: RootState) => root.plm);
    const [preferences, setPreferences] =
        useState<CollectionPreferencesProps.Preferences>(DEFAULT_PREFERENCES);
    const { ConfirmationModal, openModal } = useConfirmationModal();
    const navigate = useNavigate();
    const location = useLocation();
    const { retrievePlmInstances } = usePlmInstances();
    const isDeleteButtonDisabled = useMemo(() => {
        return (
            selectedPlmInstances.length !== 1 ||
            selectedPlmInstances[0]?.enabled ||
            selectedPlmInstances[0]?.scheduledDeletionDate !== ''
        );
    }, [selectedPlmInstances]);

    const isCancelDeleteButtonDisabled = useMemo(() => {
        return selectedPlmInstances.length !== 1 || !selectedPlmInstances[0]?.scheduledDeletionDate;
    }, [selectedPlmInstances]);

    const handleDeletion: () => Promise<ConfirmResult> = async () => {
        if (selectedPlmInstances.length !== 1) {
            return {
                isSuccess: false,
                errorMessage: {
                    title: 'Cannot Schedule Deletion',
                    content: NO_SELECTION
                }
            };
        }
        const plmInstance = selectedPlmInstances[0];
        if (plmInstance.enabled) {
            return {
                isSuccess: false,
                errorMessage: {
                    title: 'Cannot Schedule Deletion',
                    content: INSTANCE_ENABLED
                }
            };
        }
        try {
            const response = await schedulePlmInstanceDeletion(plmInstance.plmId);
            addFlashMessage({
                type: 'success',
                dismissible: true,
                content: response.statusMessage
            });
            await retrievePlmInstances();
            return { isSuccess: true };
        } catch (error) {
            return {
                isSuccess: false,
                errorMessage: {
                    title: 'Deletion Scheduling Failed',
                    content: `Failed to schedule deletion for PLM instance '${plmInstance.plmId}'. ${getErrorMessage(error)}`
                }
            };
        }
    };

    const openDeletionModal = () => {
        if (selectedPlmInstances.length !== 1) {
            addFlashMessage({
                type: 'error',
                dismissible: true,
                content: NO_SELECTION
            });
            return;
        }

        const plmInstance = selectedPlmInstances[0];

        openModal({
            title: `Schedule PLM Instance Deletion`,
            content: (
                <SpaceBetween size='m'>
                    <Box variant='span'>
                        Are you sure you want to schedule deletion for PLM instance{' '}
                        <Box variant='span' fontWeight='bold'>
                            {plmInstance.plmId}
                        </Box>
                        ?
                    </Box>

                    <Alert type='warning' statusIconAriaLabel='Warning'>
                        The actual deletion and internal resource cleanup will be scheduled to occur
                        two weeks after this confirmation.
                    </Alert>
                </SpaceBetween>
            ),
            confirmConsentText: 'confirm',
            confirmLabel: 'Delete',
            onConfirm: handleDeletion
        });
    };

    const handleCancelDeletion: () => Promise<ConfirmResult> = async () => {
        if (selectedPlmInstances.length !== 1) {
            return {
                isSuccess: false,
                errorMessage: {
                    title: 'Cannot Cancel Deletion',
                    content: NO_SELECTION
                }
            };
        }

        const plmInstance = selectedPlmInstances[0];
        try {
            const response = await cancelPlmInstanceDeletion({ plmId: plmInstance.plmId });
            addFlashMessage({
                type: 'success',
                dismissible: true,
                content: response.statusMessage
            });
            await retrievePlmInstances();
            return { isSuccess: true };
        } catch (error) {
            return {
                isSuccess: false,
                errorMessage: {
                    title: 'Cancellation Failed',
                    content: `Failed to cancel the scheduled deletion for PLM instance '${plmInstance.plmId}'. ${getErrorMessage(error)}`
                }
            };
        }
    };

    const openCancelDeletionModal = () => {
        if (selectedPlmInstances.length !== 1) {
            addFlashMessage({
                type: 'error',
                dismissible: true,
                content: NO_SELECTION
            });
            return;
        }
        const plmInstance = selectedPlmInstances[0];
        openModal({
            title: `Cancel Scheduled PLM Instance Deletion`,
            content: (
                <SpaceBetween size='m'>
                    <Box variant='span'>
                        Are you sure you want to cancel the scheduled deletion for PLM instance{' '}
                        <Box variant='span' fontWeight='bold'>
                            {plmInstance.plmId}
                        </Box>
                        ?
                    </Box>
                </SpaceBetween>
            ),
            confirmLabel: 'Cancel Deletion',
            onConfirm: handleCancelDeletion
        });
    };

    const navigateToCreatePage = () => {
        navigate(`${location.pathname}/create`);
    };

    const navigateToEditPage = () => {
        navigate(`${location.pathname}/edit`);
    };

    const { items, actions, filteredItemsCount, filterProps, paginationProps, collectionProps } =
        useCollection(plmInstances, {
            pagination: { pageSize: preferences.pageSize },
            filtering: {
                empty: (
                    <TableEmptyState
                        resourceName='PLM Instance'
                        createResourceHandler={navigateToCreatePage}
                    />
                ),
                noMatch: <TableNoMatchState clearFilterHandler={() => actions.setFiltering('')} />
            }
        });

    const getDisabledReason = (selectedInstances) => {
        if (selectedInstances.length === 0) {
            return NO_SELECTION;
        }

        const instance = selectedInstances[0];

        if (instance.enabled) {
            return INSTANCE_ENABLED;
        }

        if (instance.scheduledDeletionDate) {
            return ALREADY_SCHEDULED;
        }

        return undefined;
    };

    return (
        <SpaceBetween size='xs'>
            <Table
                {...collectionProps}
                stickyHeader={true}
                header={
                    <Header
                        variant='h2'
                        description='Manage PLM Instances'
                        actions={
                            <SpaceBetween size='xs' direction='horizontal'>
                                <Button
                                    onClick={retrievePlmInstances}
                                    ariaLabel='Refresh'
                                    iconName='refresh'
                                    loading={retrievePlmStatus === 'loading'}
                                    loadingText={'Refreshing Plm Instances'}
                                />
                                <Button
                                    data-testid='header-btn-edit'
                                    disabled={selectedPlmInstances.length !== 1}
                                    onClick={navigateToEditPage}
                                >
                                    Edit
                                </Button>
                                <Button
                                    onClick={openDeletionModal}
                                    data-testid='header-btn-delete'
                                    disabled={isDeleteButtonDisabled}
                                    disabledReason={getDisabledReason(selectedPlmInstances)}
                                >
                                    Delete
                                </Button>
                                <Button
                                    onClick={openCancelDeletionModal}
                                    data-testid='header-btn-delete'
                                    disabled={isCancelDeleteButtonDisabled}
                                    disabledReason={
                                        selectedPlmInstances.length === 0
                                            ? NO_SELECTION
                                            : selectedPlmInstances[0].scheduledDeletionDate === ''
                                              ? NO_SCHEDULED_DELETION
                                              : undefined
                                    }
                                >
                                    Cancel Delete
                                </Button>
                                <Button
                                    data-testid='header-btn-create'
                                    variant='primary'
                                    onClick={() => navigate(`${location.pathname}/create`)}
                                >
                                    Create PLM Instance
                                </Button>
                            </SpaceBetween>
                        }
                    >
                        PLM Instances
                    </Header>
                }
                items={items}
                columnDefinitions={COLUMN_DEFINITIONS}
                variant='container'
                loading={retrievePlmStatus === 'loading'}
                loadingText='Loading plm instances'
                selectionType='single'
                selectedItems={selectedPlmInstances}
                onSelectionChange={({ detail }) =>
                    setSelectedPlmInstances(detail.selectedItems as PlmInstance[])
                }
                pagination={<Pagination {...paginationProps} />}
                preferences={
                    <CollectionPreferences
                        title='Preferences'
                        confirmLabel='Confirm'
                        cancelLabel='Cancel'
                        preferences={preferences}
                        onConfirm={({ detail }) => setPreferences(detail)}
                        wrapLinesPreference={{
                            label: 'Wrap lines',
                            description: 'Check to see all the text and wrap the lines'
                        }}
                        pageSizePreference={{
                            options: [
                                { value: 10, label: '10 PLM Instances per page' },
                                { value: 20, label: '20 PLM instances per page' },
                                { value: 50, label: '50 PLM instances per page' }
                            ]
                        }}
                    />
                }
                filter={
                    <TextFilter
                        {...filterProps}
                        filteringAriaLabel='Filter PLM instances'
                        filteringPlaceholder='Find PLM instances'
                        filteringClearAriaLabel='Clear'
                        countText={
                            filteredItemsCount ? getTextFilterCounterText(filteredItemsCount) : ''
                        }
                    />
                }
            />
            <ConfirmationModal />
        </SpaceBetween>
    );
}
