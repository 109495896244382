import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { SelectProps, TableProps } from '@amzn/awsui-components-react';
import { DropdownStatusProps } from '@amzn/awsui-components-react/polaris/internal/components/dropdown-status';
import { useSelector } from 'react-redux';
import {
    GetInternalAttributesResponseContent,
    GetPlmAttributesResponseContent,
    GetProviderAttributesResponseContent,
    CreateInternalAttributeResponseContent,
    CreatePlmAttributeResponseContent,
    CreateProviderAttributeResponseContent,
    CreateInternalAttributeRequestContent,
    CreatePlmAttributeRequestContent,
    CreateProviderAttributeRequestContent,
    MapPlmAttributeRequestContent,
    MapPlmAttributeResponseContent,
    MapProviderAttributeRequestContent,
    MapProviderAttributeResponseContent,
    GetPlmAttributeMappingsResponseContent,
    GetProviderAttributeMappingsResponseContent
} from '../../open-api/generated-src/api';
import { apiInstance } from '../../index';
import { getErrorMessage, validatePlmId } from '../../utils/commons';
import {
    validateEmpty,
    ValidationConfigType,
    validateField
} from '../../utils/form-validation-config';
import { RootState } from '../../state/store';
import { AddFlashMessageType } from './useFlashMessage';
export const mappingTableEditErrorsStore = new Map();

export enum COLUMN_IDS {
    INTERNAL_ATTRIBUTE = 'internalAttribute',
    PLM_ATTRIBUTE = 'plmAttribute',
    DATA_PROVIDER_ATTRIBUTE = 'dataProviderAttribute'
}

export const NO_MAPPING_LABEL = '--- No mapping ---';
export const CLEAR_MAPPING_LABEL = '--- Clear mapping ---';

export interface MappingAttributesType {
    internalAttribute: string;
    plmAttribute: string;
    dataProviderAttribute: string;
}

export interface TableEditError {
    plmAttribute: string | undefined;
    dataProviderAttribute: string | undefined;
}

export interface CreateAttributeFormType {
    attributeCategory: string;
    attributeName: string;
    attributeType: string;
}

export type FormErrorsType = {
    attributeName: string;
    attributeType: string;
};

export const DEFAULT_FORM_DATA: CreateAttributeFormType = {
    attributeCategory: COLUMN_IDS.INTERNAL_ATTRIBUTE,
    attributeName: '',
    attributeType: ''
};

export default function useAttributes({
    addFlashMessage,
    formErrors,
    setFormErrors,
    formData,
    setFormData
}: {
    addFlashMessage: AddFlashMessageType;
    formErrors: FormErrorsType;
    setFormErrors: React.Dispatch<React.SetStateAction<FormErrorsType>>;
    formData: CreateAttributeFormType;
    setFormData: React.Dispatch<React.SetStateAction<CreateAttributeFormType>>;
}) {
    const plmId = useSelector((state: RootState) => state.plm.plmId);
    const [attributeMappings, setAttributeMappings] = useState<MappingAttributesType[]>([]);
    const [plmAttributesOptions, setPlmAttributesOptions] = useState<SelectProps.Options>([]);
    const [dataProviderAttributesOptions, setDataProviderAttributesOptions] =
        useState<SelectProps.Options>([]);
    const [isMappingsLoading, setIsMappingsLoading] = useState<boolean>(true);
    const [plmAttributesStatus, setPlmAttributesStatus] =
        useState<DropdownStatusProps.StatusType>('pending');
    const [dataProviderAttributeStatus, setDataProviderAttributeStatus] =
        useState<DropdownStatusProps.StatusType>('pending');

    const plmAttributesOptionsWithClear = useMemo(
        () => [{ label: CLEAR_MAPPING_LABEL, value: '' }, ...plmAttributesOptions],
        [plmAttributesOptions]
    );
    const [isCreateLoading, setIsCreateLoading] = useState<boolean>(false);

    const dataProviderAttributesOptionsWithClear = useMemo(
        () => [{ label: CLEAR_MAPPING_LABEL, value: '' }, ...dataProviderAttributesOptions],
        [dataProviderAttributesOptions]
    );

    const fetchAndSetData = useCallback(
        async <T>({
            apiCall,
            errorMessage,
            setData,
            errorHandler
        }: {
            apiCall: () => Promise<T>;
            errorMessage: string;
            setData?: (data: T) => void;
            errorHandler?: () => void;
        }) => {
            try {
                const result = await apiCall();
                if (setData) {
                    setData(result);
                }
                return result;
            } catch (error) {
                addFlashMessage({
                    type: 'error',
                    content: `${errorMessage} ${getErrorMessage(error)}`
                });
                if (errorHandler) {
                    errorHandler();
                }
                return null;
            }
        },
        [addFlashMessage]
    );

    const loadAttributesMapping = useCallback(async () => {
        // Skip the mapping process if plmId is not yet loaded, this prevents unnecessary API calls
        if (plmId === null) {
            return;
        }
        setIsMappingsLoading(true);

        try {
            const internalAttributes = await fetchAndSetData<GetInternalAttributesResponseContent>({
                apiCall: getInternalAttributes,
                errorMessage: 'Failed to load internal attributes.',
                setData: (data) => {
                    if (data.results) {
                        setAttributeMappings(
                            data.results.map((attr) => ({
                                internalAttribute: attr.attributeName!,
                                plmAttribute: '',
                                dataProviderAttribute: ''
                            }))
                        );
                    }
                }
            });

            if (!internalAttributes) {
                return;
            }

            if (!validatePlmId(plmId, addFlashMessage)) return;
            const [plmMappings, providerMappings] = await Promise.all([
                fetchAndSetData<GetPlmAttributeMappingsResponseContent>({
                    apiCall: () => getPlmAttributeMappings(plmId),
                    errorMessage: 'Failed to load plm attribute mappings.'
                }),
                fetchAndSetData<GetProviderAttributeMappingsResponseContent>({
                    apiCall: getProviderAttributeMappings,
                    errorMessage: 'Failed to load data provider attribute mappings.'
                })
            ]);

            // Update attributeMappings with the fetched mapping data
            setAttributeMappings((prev) =>
                prev.map((item) => ({
                    ...item,
                    plmAttribute:
                        plmMappings?.attributes.find(
                            (m) => m.internalAttribute === item.internalAttribute
                        )?.plmAttribute ?? '',
                    dataProviderAttribute:
                        providerMappings?.attributes.find(
                            (m) => m.internalAttribute === item.internalAttribute
                        )?.providerAttribute ?? ''
                }))
            );
        } catch (error) {
            addFlashMessage({
                type: 'error',
                content: `An unexpected error occurred when loading the attributes mapping. ${getErrorMessage(
                    error
                )}`
            });
        } finally {
            setIsMappingsLoading(false);
        }
    }, [fetchAndSetData, addFlashMessage, plmId]);

    const loadPlmAttributes = useCallback(async () => {
        if (plmId === null) {
            return;
        }
        setPlmAttributesStatus('loading');

        try {
            // Fetch PLM and Provider attributes in parallel
            await fetchAndSetData({
                apiCall: () => getPlmAttributes(plmId),
                errorMessage: 'Failed to load PLM attributes.',
                setData: (data) => {
                    if (data.results) {
                        setPlmAttributesOptions(
                            data.results.map((attr) => ({
                                label: attr.attributeName,
                                value: attr.attributeName
                            }))
                        );
                        setPlmAttributesStatus('finished');
                    }
                },
                errorHandler: () => {
                    setPlmAttributesStatus('error');
                }
            });
        } catch (error) {
            addFlashMessage({
                type: 'error',
                content: `An unexpected error occurred when loading the plm attributes. ${getErrorMessage(
                    error
                )}`
            });
        }
    }, [fetchAndSetData, addFlashMessage, plmId]);

    const loadDataProviderAttribute = useCallback(async () => {
        if (plmId === null) {
            return;
        }
        setDataProviderAttributeStatus('loading');

        try {
            // Fetch PLM and Provider attributes in parallel
            await fetchAndSetData({
                apiCall: getProviderAttributes,
                errorMessage: 'Failed to load data provider attributes.',
                setData: (data) => {
                    if (data.results) {
                        setDataProviderAttributesOptions(
                            data.results.map((attr) => ({
                                label: attr.attributeName,
                                value: attr.attributeName
                            }))
                        );
                        setDataProviderAttributeStatus('finished');
                    }
                },
                errorHandler: () => {
                    setDataProviderAttributeStatus('error');
                }
            });
        } catch (error) {
            addFlashMessage({
                type: 'error',
                content: `An unexpected error occurred when loading the provider attributes. ${getErrorMessage(
                    error
                )}`
            });
        }
    }, [fetchAndSetData, addFlashMessage, plmId]);

    const handleTableInlineEditSubmit: TableProps.SubmitEditFunction<MappingAttributesType> =
        useCallback(
            async (currentItem, column, value) => {
                if (!validatePlmId(plmId, addFlashMessage)) return;
                const columnId = column.id as keyof MappingAttributesType;
                if (!columnId) {
                    return;
                }
                const inlineEditErrorKey = JSON.stringify(currentItem) + column.id;
                mappingTableEditErrorsStore.delete(inlineEditErrorKey);
                const newItem = { ...currentItem, [columnId]: value };
                const updateMappings = () => {
                    setAttributeMappings(
                        attributeMappings.map((item) =>
                            item.internalAttribute === currentItem.internalAttribute
                                ? newItem
                                : item
                        )
                    );
                };

                try {
                    let response:
                        | MapPlmAttributeResponseContent
                        | MapProviderAttributeResponseContent;
                    if (columnId === COLUMN_IDS.PLM_ATTRIBUTE) {
                        response = await mapPlmAttribute({
                            internalAttribute: currentItem.internalAttribute,
                            plmId: plmId,
                            plmAttribute: value as string
                        });
                    } else if (columnId === COLUMN_IDS.DATA_PROVIDER_ATTRIBUTE) {
                        response = await mapProviderAttribute({
                            internalAttribute: currentItem.internalAttribute,
                            dataProviderAttribute: value as string
                        });
                    } else {
                        throw new Error('Invalid columnId');
                    }
                    if (response) {
                        updateMappings();
                    }
                } catch (error) {
                    mappingTableEditErrorsStore.set(inlineEditErrorKey, getErrorMessage(error));
                    throw new Error('Inline Edit error in mapping table');
                }
            },
            [attributeMappings, setAttributeMappings, plmId, addFlashMessage]
        );

    const handleFormSubmit = useCallback(
        async (e: React.FormEvent) => {
            e.preventDefault();
            if (!validatePlmId(plmId, addFlashMessage)) return;
            const newErrors = { ...formErrors };
            const VALIDATION_CONFIG: ValidationConfigType = {
                attributeName: [
                    { validate: validateEmpty, errorText: 'Attribute Name is required.' }
                ],
                attributeType: [
                    { validate: validateEmpty, errorText: 'Attribute Type is required.' }
                ]
            };
            ['attributeName', 'attributeType'].forEach((attribute) => {
                const { errorText } = validateField(
                    attribute,
                    VALIDATION_CONFIG,
                    formData[attribute as keyof FormData],
                    formData[attribute as keyof FormData]
                );
                newErrors[attribute] = errorText;
            });
            setFormErrors((prevErrors) => ({ ...prevErrors, ...newErrors }));

            if (!newErrors.attributeName && !newErrors.attributeType) {
                const { attributeCategory, attributeName, attributeType } = formData;
                setIsCreateLoading(true);
                try {
                    let response;
                    switch (attributeCategory) {
                        case COLUMN_IDS.INTERNAL_ATTRIBUTE:
                            response = await createInternalAttribute({
                                attributeName,
                                attributeType
                            });
                            setAttributeMappings((prevMapping) => [
                                {
                                    internalAttribute: attributeName,
                                    plmAttribute: '',
                                    dataProviderAttribute: ''
                                },
                                ...prevMapping
                            ]);
                            break;
                        case COLUMN_IDS.PLM_ATTRIBUTE:
                            response = await createPlmAttribute({
                                plmId: plmId,
                                attributeName,
                                attributeType
                            });
                            setPlmAttributesOptions((options) => [
                                { label: attributeName, value: attributeName },
                                ...options
                            ]);
                            break;
                        case COLUMN_IDS.DATA_PROVIDER_ATTRIBUTE:
                            response = await createProviderAttribute({
                                attributeName,
                                attributeType
                            });
                            setDataProviderAttributesOptions((options) => [
                                { label: attributeName, value: attributeName },
                                ...options
                            ]);
                            break;
                        default:
                            throw new Error('Invalid attributeCategory');
                    }
                    addFlashMessage({ type: 'success', content: response.statusMessage });
                    setFormData(DEFAULT_FORM_DATA);
                } catch (error) {
                    addFlashMessage({
                        type: 'error',
                        content: `Failed to create ${attributeCategory} '${attributeName}'. ${getErrorMessage(
                            error
                        )}`
                    });
                } finally {
                    setIsCreateLoading(false);
                }
            }
        },
        [
            plmId,
            formData,
            formErrors,
            setFormData,
            setFormErrors,
            setAttributeMappings,
            setPlmAttributesOptions,
            setDataProviderAttributesOptions,
            addFlashMessage
        ]
    );

    useEffect(() => {
        loadAttributesMapping();
    }, [loadAttributesMapping]);

    useEffect(() => {
        loadDataProviderAttribute();
        loadPlmAttributes();
    }, [loadPlmAttributes, loadDataProviderAttribute]);

    return {
        isCreateLoading,
        isMappingsLoading,
        plmAttributesStatus,
        dataProviderAttributeStatus,
        attributeMappings,
        setAttributeMappings,
        plmAttributesOptionsWithClear,
        setPlmAttributesOptions,
        dataProviderAttributesOptionsWithClear,
        setDataProviderAttributesOptions,
        handleTableInlineEditSubmit,
        handleFormSubmit,
        loadDataProviderAttribute,
        loadPlmAttributes,
        loadAttributesMapping
    };
}

export async function createInternalAttribute({
    attributeName,
    attributeType
}: CreateInternalAttributeRequestContent): Promise<CreateInternalAttributeResponseContent> {
    try {
        const response = await apiInstance
            .getApi()!
            .createInternalAttribute({ attributeName, attributeType });
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export async function createPlmAttribute({
    plmId,
    attributeName,
    attributeType
}: CreatePlmAttributeRequestContent): Promise<CreatePlmAttributeResponseContent> {
    try {
        const response = await apiInstance
            .getApi()!
            .createPlmAttribute({ plmId, attributeName, attributeType });
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export async function createProviderAttribute({
    attributeName,
    attributeType
}: CreateProviderAttributeRequestContent): Promise<CreateProviderAttributeResponseContent> {
    try {
        const response = await apiInstance
            .getApi()!
            .createProviderAttribute({ attributeName, attributeType });
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export async function mapPlmAttribute({
    internalAttribute,
    plmId,
    plmAttribute
}: MapPlmAttributeRequestContent): Promise<MapPlmAttributeResponseContent> {
    try {
        const response = await apiInstance
            .getApi()!
            .mapPlmAttribute({ internalAttribute, plmId, plmAttribute });
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export async function mapProviderAttribute({
    internalAttribute,
    dataProviderAttribute
}: MapProviderAttributeRequestContent): Promise<MapProviderAttributeResponseContent> {
    try {
        const response = await apiInstance
            .getApi()!
            .mapProviderAttribute({ internalAttribute, dataProviderAttribute });
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export async function getInternalAttributes(): Promise<GetInternalAttributesResponseContent> {
    try {
        const response = await apiInstance.getApi()!.getInternalAttributes();
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export async function getPlmAttributes(plmid): Promise<GetPlmAttributesResponseContent> {
    try {
        const response = await apiInstance.getApi()!.getPlmAttributes(plmid);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export async function getProviderAttributes(): Promise<GetProviderAttributesResponseContent> {
    try {
        const response = await apiInstance.getApi()!.getProviderAttributes();
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export async function getPlmAttributeMappings(
    plmid
): Promise<GetPlmAttributeMappingsResponseContent> {
    try {
        const response = await apiInstance.getApi()!.getPlmAttributeMappings(plmid);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export async function getProviderAttributeMappings(): Promise<GetProviderAttributeMappingsResponseContent> {
    try {
        const response = await apiInstance.getApi()!.getProviderAttributeMappings();
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}
