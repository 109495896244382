import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { getData } from '../../utils/commons';
import { SPECS_COLUMN_DEFINITIONS } from '../../config/details-config';
import {
    Header,
    SpaceBetween,
    Box,
    Button,
    ButtonDropdown,
    Table,
    Modal,
    FormField,
    FileUpload
} from '@amzn/awsui-components-react';
import { Specification, DeclaredComplianceStatus } from '../types';

export default function ComplianceTable() {
    const [specifications, setSpecifications] = useState<Specification[]>([]);
    const [specsLoading, setSpecsLoading] = useState<boolean>(true);
    const [selectedItems, setSelectedItems] = useState<Specification[]>([]);
    const [uploadModalVisiable, setUploadModalVisible] = useState<boolean>(false);
    const [file, setFile] = React.useState<File[]>([]);
    const location = useLocation();
    const navigate = useNavigate();

    // fetch specifications after render of the component
    useEffect(() => {
        getData<Specification>('specifications', (output) => {
            setSpecifications(output);
            setSpecsLoading(false);
        });
    }, []);

    const handleCreateClick = () => {
        navigate(`${location.pathname}/create-specification`);
    };

    const completeUploadModalHandler = () => {
        setUploadModalVisible(false);
        setSpecifications((prevSpecs) => {
            return prevSpecs.map((spec) => {
                if (spec.id === selectedItems[0].id) {
                    return {
                        ...spec,
                        declaredCompliance: file[0].name,
                        declaredComplianceStatus: DeclaredComplianceStatus.PENDING
                    };
                }
                return { ...spec };
            });
        });
        setSelectedItems([]);
    };

    const isOnlyOneSelected = selectedItems.length === 1;
    const atLeastOneSelected = selectedItems.length > 0;

    const handleSubmit = async (currentItem, column, value) => {
        await new Promise((resolve) => setTimeout(resolve, 1500));
        const newItem = { ...currentItem, [column.id]: value };
        setSpecifications(specifications.map((item) => (item === currentItem ? newItem : item)));
    };

    function headerCounter(selectedParts, parts) {
        return selectedParts.length
            ? `(${selectedParts.length} of ${parts.length})`
            : `(${parts.length})`;
    }
    return (
        <SpaceBetween size='l'>
            <Table
                stickyHeader={true}
                enableKeyboardNavigation={true}
                columnDefinitions={SPECS_COLUMN_DEFINITIONS}
                loading={specsLoading}
                loadingText='Loading specifications'
                items={specifications}
                selectionType='multi'
                selectedItems={selectedItems}
                onSelectionChange={(event) => setSelectedItems(event.detail.selectedItems)}
                submitEdit={handleSubmit}
                header={
                    <Header
                        counter={headerCounter(selectedItems, specifications)}
                        actions={
                            <SpaceBetween direction='horizontal' size='xs'>
                                <ButtonDropdown
                                    disabled={
                                        !(
                                            selectedItems.length === 1 &&
                                            selectedItems[0].declaredCompliance
                                        )
                                    }
                                    onItemClick={(e) => {
                                        const id = e.detail.id;
                                        switch (id) {
                                            case 'reject': {
                                                setSpecifications((prevSpecs) => {
                                                    return prevSpecs.map((spec) => {
                                                        if (spec.id === selectedItems[0].id) {
                                                            return {
                                                                ...spec,
                                                                declaredComplianceStatus:
                                                                    DeclaredComplianceStatus.REJECTED
                                                            };
                                                        }
                                                        return {
                                                            ...spec
                                                        };
                                                    });
                                                });
                                                setSelectedItems((prevSelects) => {
                                                    const newSelects = prevSelects.map(
                                                        (value, key) => {
                                                            if (key === 0) {
                                                                return {
                                                                    ...value,
                                                                    declaredComplianceStatus:
                                                                        DeclaredComplianceStatus.REJECTED
                                                                };
                                                            }
                                                            return {
                                                                ...value
                                                            };
                                                        }
                                                    );
                                                    return newSelects;
                                                });
                                                break;
                                            }
                                            case 'approval':
                                                setSpecifications((prevSpecs) => {
                                                    return prevSpecs.map((spec) => {
                                                        if (spec.id === selectedItems[0].id) {
                                                            return {
                                                                ...spec,
                                                                declaredComplianceStatus:
                                                                    DeclaredComplianceStatus.APPROVED
                                                            };
                                                        }
                                                        return {
                                                            ...spec
                                                        };
                                                    });
                                                });
                                                setSelectedItems((prevSelects) => {
                                                    const newSelects = [...prevSelects];
                                                    newSelects[0].declaredComplianceStatus =
                                                        DeclaredComplianceStatus.APPROVED;
                                                    return newSelects;
                                                });
                                                break;
                                            default:
                                                break;
                                        }
                                    }}
                                    items={[
                                        {
                                            text: 'Reject',
                                            id: 'reject',
                                            disabled: false
                                        },
                                        {
                                            text: 'Approval',
                                            id: 'approval',
                                            disabled: false
                                        }
                                    ]}
                                >
                                    Review
                                </ButtonDropdown>
                                <Button disabled={!atLeastOneSelected}>Delete</Button>
                                <Button
                                    disabled={!isOnlyOneSelected}
                                    onClick={() => setUploadModalVisible(true)}
                                >
                                    Upload Declaration PDF
                                </Button>
                                <Button onClick={handleCreateClick} variant='primary'>
                                    Create
                                </Button>
                            </SpaceBetween>
                        }
                    >
                        Specifications
                    </Header>
                }
            />
            <Modal
                onDismiss={() => setUploadModalVisible(false)}
                visible={uploadModalVisiable}
                footer={
                    <Box float='right'>
                        <SpaceBetween direction='horizontal' size='xs'>
                            <Button variant='link' onClick={() => setUploadModalVisible(false)}>
                                Cancel
                            </Button>
                            <Button variant='primary' onClick={completeUploadModalHandler}>
                                Ok
                            </Button>
                        </SpaceBetween>
                    </Box>
                }
                header='Upload Compliance Data File'
            >
                <SpaceBetween direction='vertical' size='l'>
                    <FormField label='Compliance Data' description='Upload Compliance Data File'>
                        <FileUpload
                            onChange={({ detail }) => setFile(detail.value)}
                            value={file}
                            accept='.pdf'
                            i18nStrings={{
                                uploadButtonText: (e) => (e ? 'Choose files' : 'Choose file'),
                                dropzoneText: (e) =>
                                    e ? 'Drop files to upload' : 'Drop file to upload',
                                removeFileAriaLabel: (e) => `Remove file ${e + 1}`,
                                limitShowFewer: 'Show fewer files',
                                limitShowMore: 'Show more files',
                                errorIconAriaLabel: 'Error'
                            }}
                            showFileLastModified
                            showFileSize
                            showFileThumbnail
                            tokenLimit={3}
                            constraintText='Upload file as *.pdf.'
                        />
                    </FormField>
                </SpaceBetween>
            </Modal>
        </SpaceBetween>
    );
}
