import React from 'react';
import { HelpPanel } from '@amzn/awsui-components-react';
import ExternalLinkGroup from './ExternalLinkGroup';

export default function InfoToolsContent() {
    return (
        <HelpPanel
            header={<h2>Compliance Crow</h2>}
            footer={
                <ExternalLinkGroup
                    items={[
                        {
                            href: 'https://w.amazon.com/bin/view/Amazon_Robotics/QTS/SSDS/DSD/ComplianceCrow',
                            text: 'Compliance Crow Wiki'
                        }
                    ]}
                />
            }
        >
            <p>
                Compliance Crow is a single service that collects, stores, and calculates material
                compliance data for all teams at Amazon that manufacture hardware. The service will
                allow tenants to provide BOMs or lists of parts requiring compliance evaluation,
                then automatically retrieve available compliance data. Compliance engineers will be
                able to view the tree of parts, select a compliance regulation, and view the
                compliance status of those parts with regards to the selected regulation. Compliance
                Crow will automatically keep this data up to date through nightly subscription syncs
                with the compliance data provider. Additionally, engineers can access this data via
                the PLM software of their choosing.
            </p>
        </HelpPanel>
    );
}
