import React, { useState, useCallback } from 'react';
import { Routes, Route } from 'react-router-dom';
import {
    usePartsColumnDefinitions,
    CONTENT_SELECTOR_OPTIONS,
    DEFAULT_PREFERENCES,
    DEFAULT_PAGESIZE
} from '../../config/table-config';
import {
    Flashbar,
    CollectionPreferences,
    Pagination,
    Table,
    TableProps,
    CollectionPreferencesProps,
    FlashbarProps
} from '@amzn/awsui-components-react';
import Breadcrumbs from '../common/Breadcrumbs';
import { getStringFromReactNode } from '../../utils/commons';
import CustomAppLayout from '../common/CustomAppLayout';
import PartsTableHeader from './PartsTableHeader';
import { RetrievedPartData } from '../../open-api/generated-src/api';
import { getTextFilterCounterServerSideText } from '../../utils/commons';
import useParts, { FILTER_FIELD_TYPE } from '../hooks/useParts';
import useFlashMessage from '../hooks/useFlashMessage';
import AddSinglePartPage from './AddSinglePartPage';
import AddMultiplePartsPage from './AddMultiplePartsPage';
import PartDetailsPage from '../partDetails/PartDetailsPage';
import { BaseKeyDetail } from '@amzn/awsui-components-react/polaris/internal/events';
import { TableEmptyState } from '../common';
import PartsTableFilterContainer from './PartsTableFilterContainer';

const DEFAULT_FILTER_FIELD: FILTER_FIELD_TYPE = { value: 'id', label: 'ID' };

export default function PartsTablePage() {
    const [flashMessages, setFlashMessages] = useState<FlashbarProps.MessageDefinition[]>([]);
    const [showFilteredResult, setShowFilteredResult] = useState<boolean>(false);
    const { addFlashMessage } = useFlashMessage({ setFlashMessages });
    const COLUMN_DEFINITIONS = usePartsColumnDefinitions();
    const filterFieldOptions: FILTER_FIELD_TYPE[] = COLUMN_DEFINITIONS.map((column) => ({
        value: column.id!,
        label: getStringFromReactNode(column.header)
    }));
    const [selectedParts, setSelectedParts] = useState<RetrievedPartData[]>([]);
    const [preferences, setPreferences] =
        useState<CollectionPreferencesProps.Preferences>(DEFAULT_PREFERENCES);

    const default_params = {
        pagination: {
            currentPageIndex: 1,
            pageSize: DEFAULT_PAGESIZE
        },
        sorting: {
            sortingColumn: { sortingField: COLUMN_DEFINITIONS[0].id },
            sortingDescending: false
        },
        filtering: {
            filteringText: '',
            filteringField: DEFAULT_FILTER_FIELD
        }
    };

    const {
        parts,
        currentPageIndex,
        setCurrentPageIndex,
        totalCount,
        isPartsLoading,
        filteringText,
        setFilteringText,
        filteringField,
        setFilteringField,
        sortingColumn,
        setSortingColumn,
        sortingDescending,
        setSortingDescending,
        retrievePartsCallback,
        searchPartsCallback
    } = useParts(default_params, addFlashMessage);

    const submitFilterInput = useCallback(
        async (event: CustomEvent<BaseKeyDetail>, inputText) => {
            if (event.detail.key === 'Enter') {
                await searchPartsCallback(inputText);
                setFilteringText(inputText);
                setShowFilteredResult(true);
            } else {
                setShowFilteredResult(false);
            }
        },
        [searchPartsCallback, setFilteringText]
    );

    // Keeps track of how many parts are selected
    function headerCounter(selectedParts, parts) {
        return selectedParts.length
            ? `(${selectedParts.length} of ${parts.length})`
            : `(${parts.length})`;
    }

    const handleSortingChange = ({
        detail
    }: {
        detail: TableProps.SortingState<RetrievedPartData>;
    }) => {
        setSortingDescending(detail.isDescending ?? false);
        setSortingColumn({
            sortingField: detail.sortingColumn.sortingField,
            sortingComparator: detail.sortingColumn.sortingComparator
        });
    };

    return (
        <Routes>
            <Route
                path='add-a-part'
                element={
                    <AddSinglePartPage
                        addFlashMessage={addFlashMessage}
                        retrievePartsCallback={retrievePartsCallback}
                    />
                }
            />
            <Route
                path='import-multiple-parts-from-CSV'
                element={
                    <AddMultiplePartsPage
                        addFlashMessage={addFlashMessage}
                        retrievePartsCallback={retrievePartsCallback}
                    />
                }
            />
            <Route path=':partId/*' element={<PartDetailsPage />} />
            <Route
                index
                element={
                    <CustomAppLayout
                        notifications={<Flashbar items={flashMessages} stackItems />}
                        breadcrumbs={
                            <Breadcrumbs
                                items={[
                                    {
                                        text: 'Home',
                                        href: '/parts'
                                    }
                                ]}
                            />
                        }
                        content={
                            <Table
                                variant='full-page'
                                columnDefinitions={COLUMN_DEFINITIONS}
                                items={parts}
                                loading={isPartsLoading}
                                empty={<TableEmptyState resourceName='Part' />}
                                loadingText='Loading Parts'
                                wrapLines={preferences.wrapLines}
                                columnDisplay={preferences.contentDisplay}
                                selectedItems={selectedParts}
                                onSelectionChange={({ detail }) =>
                                    setSelectedParts(detail.selectedItems as RetrievedPartData[])
                                }
                                selectionType='multi'
                                stickyHeader={true}
                                sortingDisabled={true}
                                onSortingChange={handleSortingChange}
                                sortingColumn={sortingColumn}
                                sortingDescending={sortingDescending}
                                ariaLabels={{
                                    activateEditLabel: (column, item) =>
                                        `Edit ${item.id} ${column.header}`,
                                    cancelEditLabel: (column) => `Cancel editing ${column.header}`,
                                    submitEditLabel: (column) => `Submit editing ${column.header}`,
                                    tableLabel: 'Map CSV fields to Part attributes',
                                    selectionGroupLabel: 'Parts selection',
                                    itemSelectionLabel: (_, row) => `select ${row.id}`
                                }}
                                header={
                                    <PartsTableHeader
                                        parts={parts}
                                        isPartsLoading={isPartsLoading}
                                        filteringText={filteringText}
                                        selectedParts={selectedParts}
                                        counter={headerCounter(selectedParts, parts)}
                                        columnDefinitions={COLUMN_DEFINITIONS}
                                        addFlashMessage={addFlashMessage}
                                        retrievePartsCallback={retrievePartsCallback}
                                        searchPartsCallback={searchPartsCallback}
                                    />
                                }
                                preferences={
                                    <CollectionPreferences
                                        title='Preferences'
                                        confirmLabel='Confirm'
                                        cancelLabel='Cancel'
                                        preferences={preferences}
                                        onConfirm={({ detail }) => setPreferences(detail)}
                                        contentDisplayPreference={{
                                            title: 'Select visible columns',
                                            options: CONTENT_SELECTOR_OPTIONS
                                        }}
                                        wrapLinesPreference={{
                                            label: 'Wrap lines',
                                            description:
                                                'Check to see all the text and wrap the lines'
                                        }}
                                    />
                                }
                                pagination={
                                    <Pagination
                                        currentPageIndex={currentPageIndex}
                                        pagesCount={Math.ceil(
                                            totalCount / (preferences.pageSize || DEFAULT_PAGESIZE)
                                        )}
                                        onChange={({ detail }) =>
                                            setCurrentPageIndex(detail.currentPageIndex)
                                        }
                                    />
                                }
                                filter={
                                    <PartsTableFilterContainer
                                        showFilteredResult={showFilteredResult}
                                        setShowFilteredResult={setShowFilteredResult}
                                        submitFilterInput={submitFilterInput}
                                        filterFieldOptions={filterFieldOptions}
                                        filteringField={filteringField}
                                        setFilteringField={setFilteringField}
                                        setFilteringText={setFilteringText}
                                        filteredCount={getTextFilterCounterServerSideText(
                                            parts,
                                            Math.ceil(totalCount / DEFAULT_PAGESIZE),
                                            DEFAULT_PAGESIZE
                                        )}
                                    />
                                }
                            />
                        }
                    />
                }
            />
        </Routes>
    );
}
