/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import ServiceNavigation from './ServiceNavigation';
import { AppLayout, AppLayoutProps } from '@amzn/awsui-components-react';
import InfoToolsContent from './InfoToolsContent';

interface CustomAppLayoutProps {
    notifications?: React.ReactNode;
    breadcrumbs?: React.ReactNode;
    content?: React.ReactNode;
    toolsHide?: boolean;
    navigationHide?: boolean;
    contentType?: AppLayoutProps.ContentType;
    signoutHide?: boolean;
    onFollowEnhance?: (func: () => void) => void;
}

export interface PlmIdOption {
    id: string;
    text: string;
    iconName: 'check' | undefined;
}
export const DefaultPlmIdOptions: PlmIdOption[] = [
    {
        id: 'AgilePLM',
        text: 'Agile PLM',
        iconName: undefined
    },
    {
        id: 'TeamcenterPLM',
        text: 'Teamcenter PLM',
        iconName: undefined
    }
];

export default function CustomAppLayout({
    notifications = null,
    breadcrumbs,
    content,
    toolsHide = false,
    navigationHide = false,
    contentType = 'table',
    signoutHide = false,
    onFollowEnhance,
    ...props
}: CustomAppLayoutProps) {
    return (
        <AppLayout
            {...props}
            notifications={notifications}
            navigation={
                navigationHide ? null : <ServiceNavigation onFollowEnhance={onFollowEnhance} />
            }
            breadcrumbs={breadcrumbs}
            content={content}
            contentType={contentType}
            toolsHide={toolsHide}
            tools={<InfoToolsContent />}
        />
    );
}
